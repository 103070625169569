import React, { useState, useEffect } from "react";
import AiwithText from "../components/AiwithText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ListItem, Grid, Typography, Box } from "@mui/material";
import axios from "axios";

const EmailAi = () => {
  const [prices, setPrices] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedLanguageOption, setSelectedLanguageOption] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");

  const optionsLanguage = ["Português", "Inglês"];

  useEffect(() => {
    // Fetch data from the API
    const fetchPrices = async () => {
      try {
        const response = await axios.post('https://bramer.tech/prices_list.php', { action: 'fetch' }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setPrices(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchPrices();
  }, []);

  const handleSelect = (event, value) => {
    if (value) {
      setSelectedLanguageOption(value);
    }
  };

  const handleSelectPrice = (event, value) => {
    if (value) {
      setSelectedOption(value);
      const selectedPrice = prices.find(price => price.title === value);
      setSelectedDescription(selectedPrice.description);
      if (value.includes("PT")) {
        setSelectedLanguageOption("Português");
      }
      else if (value.includes("EN")) {
       setSelectedLanguageOption("Inglês");
      }
      else {
        setSelectedLanguageOption("");
      }
    }
  };

  return (
    <Box sx={{ margin: 2, padding: 2 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
            Lista de Preço -{" "}
            <span style={{ fontWeight: "bold" }}>
              {selectedOption || "Sem seleção"}
            </span>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Idioma -{" "}
            <span style={{ fontWeight: "bold" }}>
              {selectedLanguageOption || "Sem seleção"}
            </span>
          </Typography>
       
        </Grid>
        <Grid item xs={12} md={6}>
        <Autocomplete
            disablePortal
            id="combo-box-prices"
            options={prices.map(price => price.title)}
            onChange={handleSelectPrice}
            renderInput={(params) => (
              <TextField {...params} label="Lista de Preço" variant="outlined" fullWidth />
            )}
            ListboxComponent={(props) => (
              <div {...props} style={{ maxHeight: 200, overflowY: "auto" }} />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} component="div">
                {option}
              </ListItem>
            )}
          />
      
          
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 4 }}>
        <AiwithText
         key={selectedDescription} 
          language={selectedLanguageOption || "None"} description={selectedDescription}
        />
      </Box>
    </Box>
  );
};

export default EmailAi;
