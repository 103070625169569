import React from 'react';
import { CircleLoader } from 'react-awesome-loaders';

const LoadingComponent = (props) => {

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <CircleLoader
        meshColor={"#F37920"}
        lightColor={"#f1c40f"}
        duration={1.5}
        desktopSize={"60px"}
        mobileSize={"42px"}
      />
    </div>
  );
}

export default LoadingComponent;
