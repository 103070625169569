// components/Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from '../AppRouter';
import { useTheme } from '@mui/material/styles';

const Navbar = () => {
  const theme = useTheme(); // Use o tema do MUI

  const styles = {
    navbar: {
      backgroundColor: theme.palette.primary.main, // Cor de fundo do tema
      padding: '12px',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    activeLink: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main, // Cor secundária do tema
    },
    list: {
      listStyle: 'none',
      padding: 10,
      margin: 0,
      display: 'flex',
    },
    listItem: {
      marginRight: '20px',
    },
  };

  return (
    <nav style={styles.navbar}>
      <ul style={styles.list}>
        {routes.map((route, index) => (
          route.name !== 'Login' && //route.name !== 'Lista de Preços AI' && 
          
          ( // Exclui a rota de login da navegação
            <li style={styles.listItem} key={index}>
              <NavLink
                style={styles.link}
                to={route.path}
                exact
                activeStyle={styles.activeLink}
              >
                {route.name}
              </NavLink>
            </li>
          )
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
