import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, Box, CircularProgress
} from '@mui/material';
import { Edit, Delete, Close } from '@mui/icons-material';
import LoadingComponent from '../components/LoadingComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PricesList = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState({ prices_list_id: '', title: '', description: '' });

  useEffect(() => {
    fetchPricesList();
  }, []);

  const fetchPricesList = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://bramer.tech/prices_list.php', { action: 'fetch' }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (Array.isArray(response.data)) {
        setData(response.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      setData([]);
    }
    setLoading(false);
  };

  const handleClickOpen = (item = { prices_list_id: '', title: '', description: '' }) => {
    setCurrentItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (currentItem.prices_list_id) {
        console.log('Updating item:', currentItem);
        await axios.post('https://bramer.tech/prices_list.php', {
          action: 'update',
          id: currentItem.prices_list_id,
          title: currentItem.title,
          description: currentItem.description
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } else {
        console.log('Inserting new item:', currentItem);
        await axios.post('https://bramer.tech/prices_list.php', {
          action: 'insert',
          title: currentItem.title,
          description: currentItem.description
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
      fetchPricesList();
      handleClose();
    } catch (error) {
      console.error("There was an error saving the data!", error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.post('https://bramer.tech/prices_list.php', {
        action: 'delete',
        id: id
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      fetchPricesList();
    } catch (error) {
      console.error("There was an error deleting the data!", error);
    }
    setLoading(false);
  };

  return (
    <TableContainer component={Paper}>
      <Box sx={{ margin: 2, padding: 2 }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& > :not(style)": { marginTop: 4 },
          }}
          noValidate
          autoComplete="off"
        >
          <Button variant="outlined" color="primary" onClick={() => handleClickOpen()}>Novo Item</Button>
        </Box>
        {loading ? (
          <LoadingComponent />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => (
                <TableRow key={item.prices_list_id}>
                  <TableCell sx={{ 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    maxWidth: 150 
                  }}>
                    {item.title}
                  </TableCell>
                  <TableCell sx={{ 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    maxWidth: 300 
                  }}>
                    {item.description}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleClickOpen(item)}><Edit /></Button>
                    <Button onClick={() => handleDelete(item.prices_list_id)}><Delete /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {currentItem.prices_list_id ? "Atualizar Item" : "Adicionar Novo Item"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Título"
            type="text"
            fullWidth
            value={currentItem.title}
            onChange={handleChange}
          />
          <Box sx={{ flexGrow: 1, mt: 2, height: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <TextField
              margin="dense"
              name="description"
              label="Descrição"
              type="text"
              fullWidth
              multiline
              rows={20}
              value={currentItem.description}
              onChange={handleChange}
              sx={{ height: '100%' }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

export default PricesList;
