import React, { useState, useEffect } from 'react';

const Home = () => {
  const initialText = "Bramer AI";
  const phrases = React.useMemo(() => [
    "Bramer AI :)",   // Smiley face
    "Bramer AI :D",   // Big smile
    "Bramer AI :O",   // Surprised face
    "Bramer AI ;)",   // Winking face
  ], []);

  const [displayText, setDisplayText] = useState("");
  const [currentPhrase, setCurrentPhrase] = useState(initialText);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(100);

  useEffect(() => {
    const handleTyping = () => {
      let fullText = currentPhrase;
      let updatedText = isDeleting
        ? fullText.substring(0, displayText.length - 1)
        : fullText.substring(0, displayText.length + 1);

      setDisplayText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        setTimeout(() => setIsDeleting(true), 3000); // Pause before deleting
        setTypingSpeed(50); // Increase speed for deleting effect
      } else if (isDeleting && updatedText === "") {
        setIsDeleting(false);
        const randomIndex = Math.floor(Math.random() * phrases.length);
        setCurrentPhrase(phrases[randomIndex]);
        setTypingSpeed(100); // Restore typing speed for new phrase
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timer);
  }, [displayText, isDeleting, currentPhrase, typingSpeed, phrases]);

  return (
    <div className='App' style={styles.background}>
      <div className='App-header'></div>
      <div style={styles.container}>
        <h1>{displayText}</h1>
      </div>
    </div>
  );
};

const styles = {
  background: {
    height: '100vh', // Full height background
    display: 'flex', // Center vertically
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    background: 'linear-gradient(135deg, rgba(255, 200, 150, 0.2), rgba(130, 190, 255, 0.2))', // Tons suaves de laranja e azul
  },
  container: {
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '600px',
    backgroundColor: '#fff', // White background for the text container
    fontFamily: 'Courier, monospace',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for depth
  },
};

export default Home;