// contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(true);
  const [loadingAuth, setLoadingAuth] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      //setLoadingAuth(false);
    }, 1000);
  }, []);

  const signIn = (username, password) => {
    if (username === "admin" && password === "admin") {
      setIsSignedIn(true);
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ isSignedIn, loadingAuth , signIn}}>
      {children}
    </AuthContext.Provider>
  );
};
