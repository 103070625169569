// pages/Login.js
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Container, Typography } from '@mui/material';
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext)

  
  const handleLogin = () => {
    if (signIn(username, password)) {
        navigate("/");
      } else {
        alert("Credenciais inválidas");
      }
  };

  return (
    <Container>
      <Typography variant="h4">Login</Typography>
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleLogin}>
        Login
      </Button>
    </Container>
  );
};

export default Login;
